<template>
  <section v-can="'RepMedRel1'">
    <div v-if="loading" >
      <b-skeleton animation="fade" width="100%" />
      <b-skeleton animation="fade" width="100%" />
      <b-skeleton animation="fade" width="100%" />
    </div>
    <b-table-simple id="reports-table" responsive borderless fixed v-else>
      <thead>
        <tr class="align-middle">
          <th v-if="generateBySelection">
            <Check              
              :tooltip="selectAll ? 'Desselecionar tudo': 'Selecionar tudo'"
              :value="selectAll"
              @change="handleSelectAll()"
              :key="selectAll" 
              :disabled="!generateBySelection"
            />
          </th>
          <th>Sit. repasse</th>
          <th>Data atend.</th>
          <th>Perfil/Grau part.</th>
          <th>Convênio</th>
          <th>Item</th>
          <th>Situação</th>
          <th>Forma pag.</th>
          <th>Recebido</th>
          <th>Repasse</th>
          <th>
            <span class="hide-btn" @click="() => hideRows = !hideRows">
              {{ hideRows ? 'Ver mais' : 'Ver menos' }}
            </span>
          </th>
        </tr>
      </thead>
      <tbody v-for="(onlending, index) in onlendings" :key="index">
        <tr v-if="!hideRows">
          <td v-if="generateBySelection">
            <Check              
              :value="selecteds.includes(onlending.id)"
              @change="selectOnlending(onlending)"
              :tooltip="onlending.extract ? 'Esse repasse já está presente em um extrato' : ''"
              :disabled="onlending.extract"
            />
          </td>
          <td>
            <span 
              class="situation-tag" 
              :style="`background: ${ onlending.status.includes('Pago') ? colors['Pago'] : (onlending.status.includes('Pendente') ? colors['Pendente'] : colors[onlending.status])}`"
            >
              <section class="text-tag ">{{ onlending.status }}</section>
            </span>
          </td>
          <td><p>
            {{
              onlending.item_type === 'App\\Models\\TissGuide\\Item\\TissGuideItem' ?
              (onlending.item?.execution_date ? moment(onlending.item?.execution_date).format('DD/MM/YYYY') : '') :
              (onlending.origin?.service_date ? moment(onlending.origin?.service_date).format('DD/MM/YYYY') : ''),            
            }} 
          </p></td>
          <td><p :title="onlending.rule_definition?.profile?.name">{{onlending.rule_definition?.profile?.name}}</p></td>
          <td><p :title="onlending.health_plan.fantasy_name">{{onlending.health_plan.fantasy_name}}</p></td>
          <td><p :title="onlending.item?.item?.name">{{onlending.item?.item?.name}}</p></td>
          <td>
            <span 
              class="situation-tag" 
              :style="`background: ${ onlending?.onlendingSituation?.includes('Pago') ? colors['Pago'] : (onlending?.onlendingSituation?.includes('Pendente') ? colors['Pendente'] : colors[onlending.onlendingSituation])}`"
            >
              <section class="text-tag ">{{ onlending.onlendingSituation }}</section>
            </span>
          </td>
          <td><p>
            {{
              onlending.origin_type === 'App\\Models\\TissGuide\\TissGuide' ?
                onlending.origin?.invoicing_tiss_setting?.payment_method?.name :
                onlending.payment.payment_method?.name
            }}
          </p></td>
          <td><p :title="parseNumberToMoney(onlending.total_value)">{{parseNumberToMoney(onlending.total_value)}}</p></td>
          <td>
            <p class="text-wrap" 
              :title="parseNumberToMoney(onlending.onlending_value) + '('+((onlending.onlending_value * 100) / onlending.total_value).toFixed(2)+'%)'.replace('(', ' (')"
            >
              {{ parseNumberToMoney(onlending.onlending_value) + '('+((onlending.onlending_value * 100) / onlending.total_value).toFixed(2)+'%)'.replace('(', ' (') }}
            </p>
          </td>
          <td>
            <div class="d-flex">
              <b-button v-b-toggle="`collapse-${onlending.id}-${index}`" class="toggle-btn" @click="rotateIcon">
                <ChevronDown class="icon" />
              </b-button>
              <div 
                class="options" 
                @mouseover="() => { if(index === onlendings.length-1) openedMenu = true }"
                @mouseout="openedMenu = false"
              >
                <MoreVertical class="more-icon" />
                <div class="menu">
                  <b-button 
                    v-can="'RepMedRel1'"
                    variant="link"
                    @click="openOnlendingDetailsModal(onlending)"  
                  >
                    {{'Editar e ver detalhes'}}
                  </b-button>
                </div>
              </div>
            </div>
          </td>          
        </tr>
        
        <tr v-if="!hideRows" class="collapsable-row">
          <td colspan="10">
            <b-collapse :id="`collapse-${onlending.id}-${index}`" class="collapse">
              <b-table-simple class="collapse-table">
                <thead>
                  <tr>
                    <th>Situação</th>
                    <th>Paciente</th>
                    <th>Executante na guia</th>
                    <th>Descrição</th>
                    <th>Valor faturado</th>
                    <th>Valor pago</th>
                    <th>Regra aplicada</th>
                  </tr>
                </thead>
                <tbody>
                  <tr> 
                    <td class="align-middle">
                      <span 
                        class="situation-tag" 
                        :style="`background: ${ onlending?.onlendingSituation?.includes('Pago') ? colors['Pago'] : (onlending?.onlendingSituation?.includes('Pendente') ? colors['Pendente'] : colors[onlending.onlendingSituation])}`"
                      >
                        <section class="text-tag ">{{ onlending.onlendingSituation }}</section>
                      </span>
                    </td>
                    <td><p>
                      {{
                        onlending.origin_type === 'App\\Models\\TissGuide\\TissGuide' ?
                         (onlending.origin?.appointment?.patient?.name ?? (onlending.origin?.patient?.name ?? '-')):
                         onlending.item?.appointment?.patient?.name
                      }}
                    </p></td>
                    <td><p :title="getProfessionalName(onlending.item_professional?.professional_id) || '---'">
                      {{
                        !onlending.health_plan?.fantasy_name.includes('Particular') ?
                         getProfessionalName(onlending.item_professional?.professional_id) || '---':
                         "---"
                      }}
                    </p></td>
                    <td>
                      <span>
                        <span class="blue"> 
                          {{ getItemType(onlending) }} | 
                        </span> 
                        {{ onlending.item?.item?.name }}
                      </span>
                    </td>
                    <td><p>
                      {{
                        parseNumberToMoney(onlending.item_type === 'App\\Models\\TissGuide\\Item\\TissGuideItem' ?
                         onlending.item.final_value : onlending.valorFaturado)
                      }}
                    </p></td>
                    <td><p>
                      {{
                        parseNumberToMoney(onlending.item_type === 'App\\Models\\TissGuide\\Item\\TissGuideItem' ?
                         onlending.item.value_paid : onlending.valorPago)
                      }}
                    </p></td>
                    <td><p>
                      {{'Regra #'+ onlending.rule_definition.rule.number + ' - ' +  onlending.rule_definition?.rule?.name}}
                    </p></td> 
                  </tr>
                </tbody>
              </b-table-simple>
            </b-collapse>
          </td>
        </tr>
      </tbody>
      <tr>
        <td :class="{ 'spacing': openedMenu }"></td>
      </tr>
    </b-table-simple>

    <NoFiltersResults v-if="onlendings && !onlendings.length && !loading"
      message="Os filtros aplicados não geraram nenhum resultado." 
    />
  </section>
</template>

<script>
import { parseNumberToMoney } from '@/utils/moneyHelper'

export default {
  components: {
    Check: () => import('@/components/General/Check'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    NoFiltersResults: () => import('@/components/General/noFilterResults'),
    MoreVertical: () => import('@/assets/icons/more-vertical.svg'),
  },  
  props: {
    onlendings: Array,
    generateBySelection: Boolean,
    selecteds: Array,
    loading: Boolean,
    selectAll: Boolean,
    watchAllSelected: Boolean,
    professionalsList: Array
  },
  data() {
    return {
      colors: {
        'Em aberto': '#FDFECE',
        'A revisar': '#E2CCFF',
        'Pendente': '#FCDEC2',
        Extrato: '#E7FFDB',
        Aberta: '#FCDEC2',
        Enviado: 'var(--light-orange-200)',
        'Em elaboração': '#FCDEC2',
        'Paga': 'var(--states-success-soft-green)',
        Pago: 'var(--states-success-soft-green)',
        'Pag. Parcial': '#E7FFDB',
        'Pago parcialmente': '#E7FFDB',
        'Em recurso': '#E2CCFF',
        Recuperado: 'var(--blue-100)',
        Expirado: '#FED2CE',
        Glosada: '#FED2CE',
        Glosado: '#FED2CE',
        Devolvido: '#FFF5BF',
        Autorizada: '#E7ECFE;',
        Finalizado: 'var(--neutral-500)',
        Finalizada: 'var(--neutral-500)'
      },

      hideRows: false,
      openedMenu: false,
      maxGuidesPerBatch: null
    }
  },
  methods: {
    parseNumberToMoney,
    parseDate(date) {
      return this.moment(date).format('DD/MM/YYYY')
    },
    rotateIcon($event) {
      const icon = $event.target
      icon.classList.toggle('rotate')
    },
    selectOnlending(onlending) {
      const index = this.selecteds.indexOf(onlending.id)
      if (index === -1) this.selecteds.push(onlending.id)
      else this.selecteds.splice(index, 1)

      if(this.selectAll){
        this.$emit('change-select-all', false)
      }
      else{
        if(this.selecteds?.length === this.onlendingFilters?.length){
          this.$emit('change-select-all', true)
        }
      }
    },
    handleSelectAll() {
      this.$emit('handleAllOnlendingIds', (this.watchAllSelected && !this.selectAll))
      this.$emit('change-select-all', (this.watchAllSelected && !this.selectAll))
    },

    openOnlendingDetailsModal(onlending){
      this.$emit('openOnlendingDetailsModal', onlending);
    },
    getProfessionalName(id){
      if(!id) return null;

      const professional = this.professionalsList.find(el => el.id === id);
      return professional?.name || null;
    },

    getItemType(onlending){
      return this.getFormattedType(onlending?.item?.item?.type)
    },
    
    getFormattedType(type)
    {
      switch (type) {
        case 'PROCEDURE':
            return 'Procedimento';
        case 'APPOINTMENT':
            return 'Consulta';
        case 'RETURN':
            return 'Retorno';
        case 'TELEMEDICINE':
            return 'Telemedicina';
        case 'EXAM':
            return 'Exame';
        case 'SURGICAL':
            return 'Centro cirúrgico';
        case 'PRODUCT':
            return 'Produto';
        default:
          return 'Procedimento';
      }
    }

  }
}
</script>

<style lang="scss">
#reports-table {
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
  table-layout: fixed;
  margin: 5px 0;

  thead {
    font-weight: 600;
    color: var(--dark-blue);
    border-radius: 8px 8px 0 0 ;

    tr {
      background-color: var(--neutral-100);
      &:first-child {
        border-radius: 8px 8px 0 0;
        th {
          &:first-child {
            border-radius: 8px 0 0 0;
          }
          &:last-child {
            border-radius: 0 8px 0 0;
          }
        }
      }
    }
  }

  tbody {
    color: var(--type-active);
    font-weight: 600;
    &:nth-child(odd) {
      background-color: var(--neutral-70);
    }
  }

  .spacing {
    height: 120px;
  }

  td,
  th {
    padding: 25px 15px;

    @media (max-width: 1330px) {
      font-size: 12px;
    }

    .situation-tag {
      width: 100%;
      border-radius: 16px;
      padding: 5px 10px;
      background-color: var(--neutral-300);
      display: inline-block;
      text-align: center;
    }

    .text-tag {
      text-overflow: ellipsis !important;
      overflow: hidden !important;
      white-space: nowrap !important;
    }

    .toggle-btn {
      background: var(--blue-100);
      border: none;
      padding: 5px !important;

      @media(min-width: 1440px) {
        margin-right: 50px;
      }

      @media(max-width: 1440px) {
        margin-right: 20px;
      }

      .icon {
        width: 25px;
        transition: all 500ms;
      }
    }

    p {
      max-width: 10ch;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      
      &.text-wrap {
        white-space: wrap;
      }
    }

    .multi-content {
      position: absolute;

      .multi-content-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: absolute;

        .text-container {
          max-width: 10ch !important;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      .popover-element-container {
        visibility: hidden !important;
        position: relative;
        padding: 10px;
        border-radius: 8px;
        border: 1px solid var(--neutral-300);
        background-color: var(--neutral-000);
        margin-top: 20px;
      }
    }

    .multi-content:hover {
      .popover-element-container {
        visibility: visible !important;
        p {
          max-width: 20ch !important;
        }
      }
    }

    .checkbox {
      margin-left: 25px !important;
    }

    .options {
      cursor: pointer;
      width: 24px;
      height: 28px;
      position: relative;
      display: inline-block;

      .more-icon {
        width: 24px;
        height: 24px;
        stroke: var(--neutral-500);
        transition: all 0.5s;
        cursor: pointer;
      }

      .menu {
        z-index: 10;
        width: fit-content;
        position: absolute;
        top: 28px;
        right: 0;
        background: var(--neutral-000);
        border: 1px solid var(--neutral-200);
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1),
          0px 4px 10px -8px rgba(12, 29, 89, 0.2);
        border-radius: 8px;
        padding: 0;
        opacity: 0;
        transition: all 0.5s;
        flex-direction: column;
        align-items: start;
        display: none;

        .mtb-12 {
          margin: 12px 0;
        }

        .btn {
          font-weight: 600;
          width: 100%;

          &.remove {
            color: var(--states-error);
          }

          &.btn-link {
            text-align: left;
            padding: 12px 18px !important;
          }
        }
      }

      &:hover {
        .more-icon {
          transform: rotate(90deg);
        }

        .menu {
          opacity: 1;
          display: flex;
          z-index: 10;
        }
      }
    }
  }

  .hide-btn {
    color: var(--blue-700);
    text-decoration: underline;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  td:last-child {
    overflow: visible;
  }

  .collapsable-row {
    td {
      padding: 0px;
      border-bottom: 1px dotted var(--neutral-300);
    }

    .collapse-table {
      width: calc(100% - 2rem);
      margin: 0 1rem 1.5rem;

      thead {
        tr {
          border-radius: 8px !important;
          background-color: var(--neutral-200);
          width: calc(100% + 15px);
          
          th {
            color: var(--dark-blue);
            font-weight: 600;
            font-size: 16px;
          }

          th:nth-child(1) {
            border-top-left-radius: 8px;
          }

          th:last-child {
            border-top-right-radius: 8px !important;
          }
        }
      }

      tbody {
        tr {
          border: 1px dotted var(--neutral-300);
          padding: 20px;
          td {
            padding: 15px 12px;
          }

          td:nth-child(4) {
            max-width: 35ch;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .icon {
    width: 20px;
    height: 20px;
    margin-right: 2px;
    stroke: var(--blue-500);
    cursor: pointer;

    &.trash {
      fill: none;
      stroke: var(--states-red-soft);
    }

    &.trash:hover {
      stroke: var(--states-error);
    }
  }
}

.text-align-center {
  text-align: center;
}

.rotate {
  transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
}
</style>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: var(--neutral-000);
  border-radius: 0 0 8px 0;
}

::-webkit-scrollbar-thumb {
  background-color: var(--neutral-300);
  border-radius: 100px;
  border: 3px solid var(--neutral-000);
}

::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background: var(--neutral-200);
}

.blue{
  color: #305BF2;
}

</style>
